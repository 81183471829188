import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { mapStateToProps } from 'utilities';
import { BookingHeader } from 'layouts';
import { BookingToolbar } from 'components';
import { navigate } from 'gatsby';
import { PAGES } from 'config';
import BookingLayout from '../../../containers/BookingLayout';

class BookingsThankYouPage extends PureComponent {
  constructor(props) {
    super(props);

    const { booking } = this.props;

    if (booking.currentBooking && booking.currentBooking.balance_remaining <= 0 && !booking.payments.length) {
      props.dispatch({
        type: 'MESSAGES_ADD',
        payload: {
          id: 'confirmed-booking',
          type: 'success',
          content: 'Your booking has been confirmed',
        },
      });
    }
  }

  render() {
    return (
      <BookingLayout page="bookings-review">
        <div className="l-two-col l-two-col--large">
          <BookingHeader
            title="Thanks for your booking"
            subTitle="We look forward to having you on tour soon."
          />
        </div>
        <BookingToolbar>
          <button onClick={() => navigate(PAGES.USER.PATH)} type="submit" className="c-button c-button--primary c-button--wide">Finish</button>
        </BookingToolbar>
      </BookingLayout>
    );
  }
}

BookingsThankYouPage.propTypes = {
  booking: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(BookingsThankYouPage);
